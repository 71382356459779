import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

// components
import Root from './Root';

// lazy load all the views
// landings
const Index = React.lazy(() => import('../pages/yakucap/landing'));

const Blog = React.lazy(() => import('../pages/other/Blog'));
const BlogPost = React.lazy(() => import('../pages/other/BlogPost'));

const Company = React.lazy(() => import('../pages/yakucap/company'));
const Contact = React.lazy(() => import('../pages/yakucap/contact'));
const Career = React.lazy(() => import('../pages/yakucap/career'));
const Pricing = React.lazy(() => import('../pages/yakucap/pricing'));

const PortfolioGrid = React.lazy(() => import('../pages/other/portfolio/PortfolioGrid'));
const PortfolioMasonry = React.lazy(() => import('../pages/other/portfolio/PortfolioMasonry'));
const PortfolioItem = React.lazy(() => import('../pages/other/portfolio/PortfolioItem'));

const HelpDesk = React.lazy(() => import('../pages/other/HelpDesk'));

// services
const DDoSMitigation = React.lazy(() => import('../pages/yakucap/services/ddos-mitigation'));
const CDN = React.lazy(() => import('../pages/yakucap/services/cdn'));
const Observability = React.lazy(() => import('../pages/yakucap/services/observability'));
const CaptchaSystem = React.lazy(() => import('../pages/yakucap/services/captcha'));
const LB = React.lazy(() => import('../pages/yakucap/services/load-balancing'));
const TDN = React.lazy(() => import('../pages/yakucap/services/translation-delivery-network'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const createRoutes = (base: string) => {
    return [
        {
            // root route
            path: base,
            element: <LoadComponent component={Index} />, // <Root />
        },
        {
            // public routes
            path: base,
            children: [
                {
                    path: 'pricing',
                    element: <LoadComponent component={Pricing} />,
                },
                {
                    path: 'contact',
                    element: <LoadComponent component={Contact} />,
                },
                {
                    path: 'company',
                    element: <LoadComponent component={Company} />,
                },
                {
                    path: 'services',
                    children: [
                        { path: 'ddos-mitigation', element: <LoadComponent component={DDoSMitigation} /> },
                        { path: 'content-delivery-network-cdn', element: <LoadComponent component={CDN} /> },
                        { path: 'observability', element: <LoadComponent component={Observability} /> },
                        { path: 'captcha-system', element: <LoadComponent component={CaptchaSystem} /> },
                        { path: 'load-balancing', element: <LoadComponent component={LB} /> },
                        { path: 'translation-delivery-network-tdn', element: <LoadComponent component={TDN} /> },
                    ],
                },
                {
                    path: 'pages',
                    children: [
                        {
                            path: 'blog',
                            children: [
                                { path: '', element: <LoadComponent component={Blog} /> },
                                { path: 'post', element: <LoadComponent component={BlogPost} /> },
                            ],
                        },
                        { path: 'career', element: <LoadComponent component={Career} /> },
                        { path: 'pricing', element: <LoadComponent component={Pricing} /> },
                        {
                            path: 'portfolio',
                            children: [
                                { path: 'grid', element: <LoadComponent component={PortfolioGrid} /> },
                                { path: 'masonry', element: <LoadComponent component={PortfolioMasonry} /> },
                                { path: 'item', element: <LoadComponent component={PortfolioItem} /> },
                            ],
                        },
                        { path: 'help', element: <LoadComponent component={HelpDesk} /> },
                    ],
                },
            ],
        },
    ];
};

const AllRoutes = () => {
    const langs = (window as any).language.supported.map((ln: any) => createRoutes(ln)).flat(1)
    return useRoutes([...createRoutes('/'), ...langs]);
};

export default AllRoutes;
