import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationData from './locales/trs.json';
import languageData from './locales/data.json'

// @ts-ignore
// import CountryLanguage from 'country-language';

const data = translationData as any;
// import translationEs from './locales/es/translation.json';
// import translationCn from './locales/zh-CN/translation.json';
// import translationRu from './locales/ru/translation.json';
// import translationJa from './locales/ja/translation.json';
// import translationSv from './locales/sv/translation.json';
// import translationIt from './locales/it/translation.json';
// import translationFr from './locales/fr/translation.json';
// import translationKr from './locales/ko/translation.json';
// import translationPt from './locales/pt/translation.json';
// import translationAr from './locales/ar/translation.json';
// import translationDe from './locales/de/translation.json';

//translations

const resources = {} as any;
Object.keys(data).forEach((key: string) => {
    resources[key] = { translation: data[key] };
});


// console.log(CountryLanguage)
// console.log(CountryLanguage.getLanguageCodes(2))

window.language = {
    data: languageData as any
};

window.language.supported = Object.keys(resources);
window.language.iterate = ['en', ...Object.keys(resources)];


declare global {
    interface Window {
        language: any; // 👈️ turn off type checking
        Navigator: any;
    }
}

window.language.find = (ln: string) =>
    window.language.data.find(({ code }: any) => code === ln) ?? {
        name: '',
        code: '',
        flag: '',
    };

window.language.findName = (ln: string) =>
    window.language.data.find(({ name }: any) => name === ln) ?? {
        name: '',
        code: '',
        flag: '',
    };

const getLocale = () => {
    let lang = window.navigator.languages ? window.navigator.languages[0] : null;
    lang =
        lang ||
        window.navigator.language ||
        (window as any).navigator.browserLanguage ||
        (window as any).navigator.userLanguage;

    if (window.language.supported.find((ln: string) => ln === lang))
        return window.language.supported.find((ln: string) => ln === lang);

    let shortSplit: string[] = [];
    if (lang?.indexOf('-') !== -1) shortSplit = lang?.split('-') ?? [];

    if (lang?.indexOf('_') !== -1) shortSplit = lang?.split('_') ?? [];

    for (let target of shortSplit) {
        if (target === 'zh-TW') target = 'tw';
        // else if (target === 'zh-HK') target = 'hk'
        else if (target === 'zh') target = 'cn';

        const found = window.language.supported.find((ln: string) => ln === target);
        if (found) return found;
    }
    return undefined;
};

const getLanguage = () => {


    const lang =
        window.language.supported.find((ln: string) => window.location.pathname.startsWith(`/${ln}`)) ||
        localStorage.getItem('I18N_LANGUAGE') ||
        getLocale() ||
        'en';
    localStorage.setItem('I18N_LANGUAGE', lang);
    window.language.prefix = lang !== 'en' ? `/${lang}` : '';
    return lang;
};
// const language = localStorage.getItem("I18N_LANGUAGE")
// if (!language) {
//   localStorage.setItem("I18N_LANGUAGE", "en")
// }

i18n.use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: getLanguage(),
        fallbackLng: 'en', // use en if detected lng is not available

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
