import { useEffect } from 'react';

import AOS from 'aos';

// routes
import Routes from './routes/Routes';

// helpers
import { configureFakeBackend } from './helpers';

// Themes
//  For Default import Theme.scss
import './assets/scss/theme.scss';

const App = () => {
    useEffect(() => {
        AOS.init();
        if(localStorage.getItem('I18N_LANGUAGE') === 'ar')
            document.body.style.direction = 'rtl'
        else
            document.body.style.direction = 'ltr'
    }, []);

    // configureFakeBackend();
    return <Routes />;
};

export default App;
